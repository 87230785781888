import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pancakeswap-libs/uikit'
import Page from './layout/Page'
// import logo from '../assets/loader.gif'

// const Wrapper = styled(Page)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

const PageLoader: React.FC = () => {
  return (
    <div style={{
      position: 'fixed',
	    left: '0px',
    	top: '0px',
	    width: '100%',
    	height: 'calc(100vh - 64px)',
    	zIndex: 9999,
      background:`url('../loader.gif') center no-repeat #000`,
      backgroundSize:'200px'
     }}>
      {/* <img width='50%' height='50%' src={logo} alt="Loading..." /> */}
      
      {/* Loading */}
    </div>
  )
}

export default PageLoader
