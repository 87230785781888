import styled from 'styled-components'

const Card = styled.div<{ isActive?: boolean; isFinished?: boolean }>`
  background: ${(props) => props.theme.card.background};
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%) !important;
  border:1px solid #A28BD4 !important;
  border-radius: 32px;
  display: flex;
  background-color: rgba(0,0,0,0.7) !important;
  color: ${({ isFinished, theme }) => theme.colors[isFinished ? 'textDisabled' : 'secondary']};
  box-shadow: ${({ isActive }) =>
    isActive
      ? '0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4);'
      : '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)'};
  flex-direction: column;
  position: relative;
    }
    .changestackeColor{
      background-color: #483F5A !important;
      border: 3px solid #afabc9 !important;
      box-shadow: 0 5px 20px rgb(0 0 0 / 10%) !important;
}
  }
  .changestackeColor:hover{
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
`

export default Card
